<div class="crud-page">
  <div class="page-header replaced-with-2-lines">
    <a routerLink="/pages-management" class="link back-button"><mat-icon>arrow_left</mat-icon>{{'PAGES.PAGES_MANAGEMENT.BACK' | translate}}</a>
    <h1>{{page?.title}}</h1>
    <h2>{{page?.subTitle}}</h2>
    <button class="toolbar" mat-flat-button color="primary" routerLink="/page-edit/{{page?.id}}">{{'BUTTONS.EDIT_PAGE' | translate}}</button>
    <button (click)="confirmRemovePage($event, pageId)" class="toolbar" color="primary" mat-flat-button>{{'BUTTONS.DELETE_PAGE' | translate}}</button>
  </div>
  <div class="page-content top-breadcrumbs">
    <app-breadcrumbs></app-breadcrumbs>
    <div class="view-container wide" *ngIf="page">
      <div class="items">
        <span class="value in-row">
          <mat-checkbox [checked]="page?.main" (click)="$event.preventDefault()">{{'PAGES.PAGES_MANAGEMENT.IS_MAIN' | translate}}</mat-checkbox>
          <mat-radio-group [value]="page?.categoryType">
            <!--hide OBSERVATION option-->
            <!--<mat-radio-button value="{{categoryTypes.OBSERVATION}}" (click)="$event.preventDefault()">{{categoryTypes.OBSERVATION}}</mat-radio-button>-->
            <mat-radio-button value="{{categoryTypes.VIOLATION}}" (click)="$event.preventDefault()">{{categoryTypes.VIOLATION}}</mat-radio-button>
          </mat-radio-group>
        </span>

        <span class="unconnected-pages">
          <mat-icon class="mat-icon material-icons">noise_control_off</mat-icon>
          {{'PAGES.PAGES_MANAGEMENT.UNCONNECTED_PAGES' | translate}} ({{unconnectedPagesNumber}})
        </span>

        <mat-list role="list" *ngIf="page?.children?.[0]" class="categories-list">
          <mat-list-item role="listitem" *ngFor="let child of page?.children" >
            <img [src]="child.pageId > 0 ? 'assets/icons/category.png' : 'assets/icons/red-category.png'">
            <span class="category-name" [class.category-only]="child.pageId === 0">
              {{getCategoryName(child.categoryId)}}
            </span>

            <mat-form-field class="category-name" [class.category-only]="child.pageId === 0" *ngIf="false">
              <mat-select [(value)]="child.categoryId" class="readonly-block">
                <mat-option [value]="child.categoryId">
                  {{getCategoryName(child.categoryId)}}
                </mat-option>
              </mat-select>
            </mat-form-field>


            <span class="page-title" *ngIf="child.pageId > 0">
              <img src="assets/icons/link.png" />
              <img src="assets/icons/page.png" />

              <span class="page-name">
                {{getPageTitle(child.pageId)}}
              </span>
            </span>

            <span class="page-title" *ngIf="child.pageId === 0">
              <img src="assets/icons/link.png" />
              <img src="assets/icons/no-page.png" />

              <span class="page-name">
                {{'PAGES.PAGES_MANAGEMENT.NO_PAGE_CONNECTED' | translate}}
              </span>
            </span>

            <button mat-flat-button class="goto-page" *ngIf="child.pageId !== 0" (click)="gotoChild(child)">{{'BUTTONS.GOTO_PAGE' | translate}}</button>

            <span class="sea-name" [class.category-only]="child.pageId === 0" [class.fillAbsentButtonSpace]="child.pageId === 0">
              {{child.sea}}
            </span>

            <mat-checkbox [checked]="child.promoted" (click)="$event.preventDefault()">{{'PAGES.PAGES_MANAGEMENT.PROMOTED' | translate}}</mat-checkbox>
          </mat-list-item>
        </mat-list>
      </div>
      <app-page-preview [items]="previewItems.getValue()"></app-page-preview>
    </div>
  </div>
</div>
