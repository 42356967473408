<div class="reset-password-form-container">
  <img src="assets/images/logo-light.svg" />
  <mat-card>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="resetPassword()">
        <h2>{{'PAGES.RESET_PASSWORD.TITLE' | translate}}</h2>
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="{{'PAGES.RESET_PASSWORD.PASSWORD' | translate}}" formControlName="password" required>
          <mat-error *ngIf="checkError('password', 'required')">{{'PAGES.RESET_PASSWORD.ERRORS.REQUIRED_PASSWORD' | translate}}</mat-error>
          <mat-error *ngIf="checkError('password', 'pattern')">{{'PAGES.SETUP_PASSWORD.ERRORS.PATTERN' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="{{'PAGES.RESET_PASSWORD.REPEAT_PASSWORD' | translate}}" formControlName="repeatPassword" required>
          <mat-error *ngIf="checkError('repeatPassword', 'required')">{{'PAGES.RESET_PASSWORD.ERRORS.REQUIRED_PASSWORD' | translate}}</mat-error>
          <mat-error *ngIf="checkError('repeatPassword', 'mustMatch')">{{'PAGES.RESET_PASSWORD.ERRORS.MUST_MATCH' | translate}}</mat-error>
        </mat-form-field>
        <a class="now-i-know-password" routerLink="/login">{{'PAGES.RESET_PASSWORD.ALREADY_KNOW_YOUR_PASSWORD' | translate}}</a>
        <button mat-raised-button color="primary" [disabled]="!form.valid">{{'BUTTONS.RESET_PASSWORD' | translate}}</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
