<div class="page-header">
  <a routerLink="/reports-tickets" class="link back-button"
    ><mat-icon>arrow_left</mat-icon>{{ "PAGES.REPORTS_TICKETS.BACK" | translate }}</a
  >
  <h1 *ngIf="reportData?.reportDate">{{ reportData.reportDate + "Z" | date : "dd/MM/yy (HH:mm)" }}</h1>
  <div class="report-status">
    <span *ngIf="reportData?.reportStatusName"
      >{{ "PAGES.REPORTS_TICKETS.STATUS" | translate }}: <b>{{ reportData.reportStatusName }}</b
      ><br
    /></span>
    <span *ngIf="reportData?.reportType"
      >{{ "PAGES.REPORTS_TICKETS.TYPE" | translate }}: <b>{{ reportData.reportType }}</b
      ><br
    /></span>
    <span *ngIf="reportData?.backOfficeUserFullName"
      >{{ "PAGES.REPORTS_TICKETS.LAST_CHANGED_BY" | translate }}: <b>{{ reportData.backOfficeUserFullName }}</b></span
    >
  </div>
  <button class="toolbar" mat-flat-button color="primary" (click)="editReport()">{{ "BUTTONS.VIEW_REPORT" | translate }}</button>
</div>
<div class="page-content">
  <div class="columns-block">
    <div>
      <div class="item" *ngIf="reportData.categories?.[0]">
        <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.CATEGORY" | translate }}</span>
        <span class="categories" *ngFor="let category of reportData.categories"> ↳ {{ category.name }} </span>
      </div>

      <div class="item">
        <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.REPORTER" | translate }}</span>
        {{ reportData.firstName }} {{ reportData.lastName }}
      </div>

      <div class="item">
        <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.PHONE" | translate }}</span>
        {{ reportData.phoneNumber }}
      </div>

      <div class="item" *ngIf="reportData.email">
        <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.EMAIL" | translate }}</span>
        {{ reportData.email }}
      </div>

      <div class="item" *ngIf="reportData.seaRelations">
        <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.MY_RELATIONS_TO_THE_SEA" | translate }}</span>
        {{ reportData.seaRelations.join(", ") }}
      </div>

      <div class="item" *ngIf="reportData.createdDate">
        <span class="column-header">{{ "PAGES.REPORTS.CREATED_DATE" | translate }}</span>
        {{ reportData.createdDate + "Z" | date : "dd/MM/yy (HH:mm)" }}
      </div>
    </div>
    <div *ngIf="reportData.images?.length > 0">
      <div class="item">
        <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.PHOTO_FROM_REPORT" | translate }}</span>
        <lightgallery [settings]="gallerySettings">
          <ng-container *ngFor="let image of reportData.images">
            <img *ngIf="!isVideo(image)" class="gallery-img thumbnails zoom" [src]="image" />

            <a class="video-thumb-container gallery-img" *ngIf="isVideo(image) && image" [attr.data-video]="getDataVideo(image)">
              <video class="thumbnails gallery-img" [controls]="false" [autoplay]="false" [muted]="true">
                <source [src]="image" [type]="'video/' + getVideoFileExtension(image)" />
              </video>
              <button class="play-button" type="button" aria-disabled="false">
                <span class="play-button-icon-placeholder" aria-hidden="true"></span>
              </button>
            </a>
          </ng-container>
        </lightgallery>
      </div>
    </div>
    <div *ngIf="!!reportData.description || !!reportData.metricObservationData">
      <div class="item" *ngIf="!!reportData.description">
        <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.DESCRIPTION" | translate }}</span>
        {{ reportData.description }}
      </div>
      <div class="item" *ngIf="reportData.metricObservationData">
        <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.METRIC_OBSERVATION_DATA" | translate }}</span>
        <span [innerHTML]="reportData.metricObservationData.split('\n').join('<br />')"></span>
      </div>
      <div class="item" *ngIf="sendLogs.length > 0">
        <span class="column-header">{{ "PAGES.REPORTS.SENDING_LOGS" | translate }}</span>
        <table mat-table [dataSource]="sendLogs" >

          <ng-container matColumnDef="sentTo">
            <th mat-header-cell *matHeaderCellDef> {{ "PAGES.REPORTS.SENT_TO" | translate }} </th>
            <td mat-cell *matCellDef="let log"> {{log.backOfficeUserFullName}} </td>
          </ng-container>

          <ng-container matColumnDef="sentBy">
            <th mat-header-cell *matHeaderCellDef> {{ "PAGES.REPORTS.SENT_BY" | translate }} </th>
            <td mat-cell *matCellDef="let log"> {{log.sendMethod}} </td>
          </ng-container>

          <ng-container matColumnDef="sentStatus">
            <th mat-header-cell *matHeaderCellDef> {{ "PAGES.REPORTS.SENT_STATUS" | translate }} </th>
            <td mat-cell *matCellDef="let log"> {{log.sendStatus}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="sendingLogsTableDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: sendingLogsTableDisplayedColumns;"></tr>
        </table>

      </div>
    </div>
    <div>
      <div class="item">
        <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.LOCATION" | translate }}</span>
        <div id="google-map"></div>
      </div>

      <a href="https://maps.google.com/?q={{ reportData.latitude }},{{ reportData.longitude }}" target="_blank" class="map light-link"
        >GoogleMaps {{ reportData.location }}</a
      >
    </div>
  </div>
  <div class="logs-block" *ngIf="logs.length > 0">
    <span class="column-header">{{ "PAGES.REPORTS.LOGS" | translate }}</span>
    <table mat-table [dataSource]="logs" class="mat-elevation-z3">

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef class="column-150"> {{ "PAGES.REPORTS.LOG_TABLE.CREATED_DATE" | translate }} </th>
        <td mat-cell *matCellDef="let log" class="column-150"> {{log.createdDate + 'Z' | date:"dd.MM.yy (HH:mm)"}} </td>
      </ng-container>

      <ng-container matColumnDef="backOfficeUserFullName">
        <th mat-header-cell *matHeaderCellDef class="column-150"> {{ "PAGES.REPORTS.LOG_TABLE.USER_FULL_NAME" | translate }} </th>
        <td mat-cell *matCellDef="let log" class="column-150"> {{log.backOfficeUserFullName}} </td>
      </ng-container>

      <ng-container matColumnDef="reportStatusName">
        <th mat-header-cell *matHeaderCellDef class="column-250"> {{ "PAGES.REPORTS.LOG_TABLE.REPORT_STATUS_NAME" | translate }} </th>
        <td mat-cell *matCellDef="let log" class="column-250"> {{log.reportStatusName}} </td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef> {{ "PAGES.REPORTS.LOG_TABLE.NOTE" | translate }} </th>
        <td mat-cell *matCellDef="let log"> {{log.note}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="logsTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: logsTableDisplayedColumns;"></tr>
    </table>
  </div>
</div>
