<div class="page-header">
  <h1>{{'PAGES.MESSAGES_MANAGEMENT.TITLE' | translate}}</h1>
  <button class="toolbar" mat-flat-button color="primary"
          (click)="saveMessages()"
          [disabled]="!form.valid">
    {{'BUTTONS.SAVE_CHANGES' | translate}}
  </button>
</div>
<div class="page-content">
  <div class="view-container">
    <form [formGroup]="form" class="vertical-form">

      <p>
        <mat-icon class="info">info</mat-icon>{{'PAGES.MESSAGES_MANAGEMENT.LINK_HINT' | translate: '{link: "'+appConstants.linkPlaceholder+'"}'}}
      </p>

      <div class="item">
        <mat-checkbox formControlName="smsIsActive">{{'PAGES.MESSAGES_MANAGEMENT.SMS' | translate}}</mat-checkbox>
        <mat-form-field class="textarea">
          <mat-label>{{'PAGES.MESSAGES_MANAGEMENT.SMS_TITLE' | translate: '{limit: "'+appConstants.messageTextMaxLength.sms+'"}'}}</mat-label>
          <textarea formControlName="smsMessage" matInput cdkTextareaAutosize
                    [maxlength]="getMaxMessageLength(communicationChannel.SMS)"
                    [required]="form.get('smsIsActive')?.value"></textarea>
          <mat-error *ngIf="checkError('smsMessage', 'required')">
            {{'PAGES.MESSAGES_MANAGEMENT.ERRORS.REQUIRED_SMS_TEXT' | translate}}
          </mat-error>
          <mat-error *ngIf="checkError('smsMessage', 'existingLinkSMS')">
            {{'PAGES.MESSAGES_MANAGEMENT.ERRORS.REQUIRED_LINK_SMS' | translate: '{link: "'+appConstants.linkPlaceholder+'"}'}}
          </mat-error>
          <span class="counter">
            {{getMessageLength(form.get('smsMessage')?.value)}}/{{appConstants.messageTextMaxLength.sms}}
            ({{linkPatternLength}} {{'PAGES.MESSAGES_MANAGEMENT.ARE_RESERVED_FOR_LINK' | translate}})
          </span>
        </mat-form-field>
      </div>

      <div class="item">
        <mat-checkbox formControlName="emailIsActive">{{'PAGES.MESSAGES_MANAGEMENT.EMAIL' | translate}}</mat-checkbox>
        <ngx-editor-menu
          [editor]="emailEditor"
          [toolbar]="emailEditorToolbar"
        >
        </ngx-editor-menu>
        <ngx-editor matInput
          [editor]="emailEditor"
          placeholder="{{'PAGES.MESSAGES_MANAGEMENT.EMAIL_TITLE' | translate}}"
          [required]="form.get('emailIsActive')?.value"
          formControlName="emailMessage"
        >
        </ngx-editor>
        <mat-error class="error-text" *ngIf="checkError('emailMessage', 'required')">
          {{'PAGES.MESSAGES_MANAGEMENT.ERRORS.REQUIRED_EMAIL_TEXT' | translate}}
        </mat-error>
        <mat-error class="error-text" *ngIf="checkError('emailMessage', 'existingLinkEmail')">
          {{'PAGES.MESSAGES_MANAGEMENT.ERRORS.REQUIRED_LINK_EMAIL' | translate: '{link: "'+appConstants.linkPlaceholder+'"}'}}
        </mat-error>
      </div>

      <!--hidden WhatsApp-->
      <!--<div class="item">
        <mat-checkbox formControlName="whatsappIsActive">{{'PAGES.MESSAGES_MANAGEMENT.WHATSAPP' | translate}}</mat-checkbox>
        <p [innerHTML]="getMultiLineTranslation('PAGES.MESSAGES_MANAGEMENT.WHATSAPP_DISCLAIMER')" class="whatsapp-disclaimer"></p>
        <p [innerHTML]="translate.instant('PAGES.MESSAGES_MANAGEMENT.WHATSAPP_DISCLAIMER_EXAMPLE_RTL')" style="direction: rtl"></p>
        <mat-form-field class="textarea">
          <mat-label>{{'PAGES.MESSAGES_MANAGEMENT.WHATSAPP_TITLE' | translate: '{limit: "'+appConstants.messageTextMaxLength.whatsapp+'"}'}}</mat-label>
          <textarea formControlName="whatsappMessage" matInput cdkTextareaAutosize
                    [maxlength]="getMaxMessageLength(communicationChannel.WHATSAPP)"
                    [required]="form.get('whatsappIsActive')?.value"></textarea>
          <mat-error *ngIf="checkError('whatsappMessage', 'required')">
            {{'PAGES.MESSAGES_MANAGEMENT.ERRORS.REQUIRED_WHATSAPP_TEXT' | translate}}
          </mat-error>
          <span class="counter">
            {{getMessageLength(form.get('whatsappMessage')?.value)}}/{{appConstants.messageTextMaxLength.whatsapp}}
          </span>
        </mat-form-field>
      </div>-->
    </form>
    <div class="toggler">
      <mat-slide-toggle [(ngModel)]="sendToAdminOnly" (change)="saveToggler()">{{'PAGES.MESSAGES_MANAGEMENT.SEND_TO_ADMIN_ONLY' | translate}}</mat-slide-toggle>
    </div>
  </div>
</div>
