<div class="crud-page">
  <div class="page-header">
    <a routerLink="/categories" class="link back-button"><mat-icon>arrow_left</mat-icon>{{'PAGES.REPORT_CATEGORIES.BACK' | translate}}</a>
    <h1>{{category?.name}}</h1>
    <button class="toolbar" mat-flat-button color="primary" (click)="editReportCategory()">{{'BUTTONS.VIEW_REPORT_CATEGORY' | translate}}</button>
  </div>
  <div class="page-content">
    <div class="view-container">
      <div class="items" *ngIf="category?.createdDate">
        <span class="header">{{'PAGES.REPORT_CATEGORIES.CREATED_DATE' | translate}}</span>
        <span class="value">{{category?.createdDate + 'Z' | date:"dd.MM.yy (HH:mm)"}}</span>
      </div>

      <div class="items" *ngIf="category?.updatedDate">
        <span class="header">{{'PAGES.REPORT_CATEGORIES.UPDATED_DATE' | translate}}</span>
        <span class="value">{{category?.updatedDate + 'Z' | date:"dd.MM.yy (HH:mm)"}}</span>
      </div>

      <div class="items" *ngIf="category?.logoUri">
        <span class="header">{{'PAGES.REPORT_CATEGORIES.ICON' | translate}}</span>
        <div class="value icon-preview">
          <lightgallery [settings]="gallerySettings">
            <img [src]="category.logoUri" class="zoom"/>
          </lightgallery>
        </div>
      </div>

      <div class="items" *ngIf="category?.hint">
        <span class="header">{{'PAGES.REPORT_CATEGORIES.HINT' | translate}}</span>
        <span class="value">{{category?.hint}}</span>
      </div>
    </div>

    <div class="view-container" *ngIf="category?.content">
      <div class="items">
        <span class="header">{{'PAGES.REPORT_CATEGORIES.CONTENT_DESCRIPTION' | translate}}</span>
        <span class="id">{{'PAGES.REPORT_CATEGORIES.ID' | translate}}: {{category?.content?.id}}</span>
        <span class="value">{{category?.content?.text}}</span>
      </div>

      <div class="items" *ngIf="category?.content?.logoUri">
        <span class="header">{{'PAGES.REPORT_CATEGORIES.CONTENT_IMAGE' | translate}}</span>
        <div class="value icon-preview">
          <lightgallery [settings]="gallerySettings">
            <img [src]="category.content.logoUri" class="zoom"/>
          </lightgallery>
        </div>
      </div>
    </div>
  </div>
</div>
