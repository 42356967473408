<div class="login-form-container">
  <img src="assets/images/logo-light.svg" />
  <mat-card>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="sendPIN()">
        <h2>{{'PAGES.LOGIN_PIN.TITLE' | translate}}</h2>
        <div class="hint">{{'PAGES.LOGIN_PIN.HINT' | translate}}</div>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="{{'PAGES.LOGIN_PIN.PIN' | translate}}" formControlName="pin" required>
          <mat-error>{{'PAGES.LOGIN_PIN.ERRORS.INVALID_PIN' | translate}}</mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!form.valid">{{'BUTTONS.VERIFY_PIN' | translate}}</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
