<div class="reset-form-container">
  <img src="assets/images/logo-light.svg" />
  <mat-card>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="sendResetPasswordEmail()">
        <h2>{{'PAGES.FORGOT_PASSWORD.TITLE' | translate}}</h2>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="{{'PAGES.FORGOT_PASSWORD.EMAIL' | translate}}" formControlName="email" type="email" required>
          <mat-error>{{'PAGES.FORGOT_PASSWORD.ERRORS.INVALID_EMAIL' | translate}}</mat-error>
        </mat-form-field>
        <a class="now-i-know-password" routerLink="/login">{{'PAGES.FORGOT_PASSWORD.ALREADY_KNOW_YOUR_PASSWORD' | translate}}</a>
        <button mat-raised-button color="primary" [disabled]="!form.valid">{{'BUTTONS.RESET_PASSWORD' | translate}}</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
