<div class="login-form-container">
  <img src="assets/images/logo-light.svg" />
  <mat-card>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="login()">
        <h2>{{'PAGES.LOGIN.TITLE' | translate}}</h2>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="{{'PAGES.LOGIN.EMAIL' | translate}}" formControlName="email" required>
          <mat-error>{{'PAGES.LOGIN.ERRORS.INVALID_EMAIL' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="{{'PAGES.LOGIN.PASSWORD' | translate}}" formControlName="password" required>
          <mat-error>{{'PAGES.LOGIN.ERRORS.INVALID_PASSWORD' | translate}}</mat-error>
        </mat-form-field>
        <a class="forgot-password" routerLink="/forgot-password">{{'PAGES.LOGIN.FORGOT_YOUR_PASSWORD' | translate}}</a>
        <button mat-raised-button color="primary" [disabled]="!form.valid">{{'BUTTONS.LOG_IN' | translate}}</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
