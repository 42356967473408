import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from './shared/services/loading.service';
import { Subject } from "rxjs";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "./shared/services/auth/auth.service";
import { BackofficeUserRoles} from "./shared/enums/roles.enum";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  standAloneComponents = [
    '/login',   // login page
    '/invite',  // setup password page
    '/page404', // not found page
    '/forgot-password', // forgot password
    '/reset', // reset password

    '/report/'
  ];

  constructor(
    public router: Router,
    private loading: LoadingService,
    private auth: AuthService,
    translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  isLoading: Subject<boolean> = this.loading.isLoading;

  get isStandAlone(): boolean {
    let standAlone = false;
    this.standAloneComponents.forEach(component => {
      if (this.router.url.indexOf(component) > -1) {
        standAlone = true;
      }
    })
    return standAlone;
  }

  get isReport(): boolean {
    return this.router.url.indexOf('/report/') > -1;
  }

  public ifUserHasAccess(page: string) {
    const roleId = this.auth.userProfile.getValue().roleId;

    const allowedPages: any = {
      backofficeUsers : [BackofficeUserRoles.ADMIN],
      applicationUsers : [BackofficeUserRoles.ADMIN],
      reserves : [BackofficeUserRoles.ADMIN],
      reports : [BackofficeUserRoles.ADMIN, BackofficeUserRoles.REPORT_USER],
      categories : [BackofficeUserRoles.ADMIN],
      contentManagement : [BackofficeUserRoles.ADMIN],
      reportStatusManagement : [BackofficeUserRoles.ADMIN],
      notifications : [BackofficeUserRoles.ADMIN]
    };

    return allowedPages[page]?.includes(roleId);
  }

}
