<mat-toolbar color="primary" [class.without-button]="reportHeader">
  <button mat-icon-button (click)="toggleSidebar()" *ngIf="!reportHeader">
    <mat-icon color="accent">menu_open</mat-icon>
  </button>
  <a class="logo" routerLink="/">
    <img src="assets/images/logo-light.svg" />
  </a>
  <span class="spacer"></span>
  <app-user-profile-toolbar *ngIf="!reportHeader"></app-user-profile-toolbar>
  <span class="inspector-name" *ngIf="reportHeader && inspectorReportService.currentInspectorName">
    <mat-icon color="accent">perm_identity</mat-icon>
    {{inspectorReportService.currentInspectorName}}
  </span>
</mat-toolbar>
