<div class="crud-page">
  <div class="page-header">
    <a routerLink="/content-management" class="link back-button">
      <mat-icon>arrow_left</mat-icon>
      {{'PAGES.CONTENT.BACK' | translate}}</a>
    <h1>{{'PAGES.CONTENT_UPDATE.TITLE' | translate}}</h1>
    <button class="toolbar" mat-flat-button color="primary" (click)="updateContent()" [disabled]="!form.valid">
      {{'BUTTONS.EDIT_CONTENT' | translate}}
    </button>
  </div>
  <div class="page-content">
    <form [formGroup]="form" class="vertical-form columns">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>{{'PAGES.CONTENT.NAME' | translate}}</mat-label>
          <input matInput formControlName="contentName" required>
          <mat-error *ngIf="checkError('contentName', 'required')">{{'PAGES.CONTENT.ERRORS.REQUIRED_CONTENT_NAME' | translate}}</mat-error>
          <mat-error *ngIf="checkError('contentName', 'minlength')">{{'PAGES.CONTENT.ERRORS.TOO_SHORT_CONTENT_NAME' | translate}}</mat-error>
        </mat-form-field>

        <span class="column-header">{{'PAGES.CONTENT.DESCRIPTION' | translate}} *</span>
        <mat-card class="without-underline">
          <mat-form-field appearance="fill" floatLabel="never">
            <textarea matInput formControlName="description"></textarea>
            <mat-error *ngIf="checkError('description', 'required')">{{'PAGES.CONTENT.ERRORS.REQUIRED_DESCRIPTION' | translate}}</mat-error>
          </mat-form-field>
        </mat-card>

        <span class="column-header">{{'PAGES.CONTENT.IMAGE' | translate}}</span>
        <div class="image-hints">
          {{'PAGES.CONTENT.IMAGE_HINTS_LINE_1' | translate}}<br />
          {{'PAGES.CONTENT.IMAGE_HINTS_LINE_2' | translate}}<br />
          {{'PAGES.CONTENT.IMAGE_HINTS_LINE_3' | translate}}
        </div>
        <div class="icon-preview" *ngIf="imagePreview">
          <img [src]="imagePreview | safe:'resourceUrl'"/>
        </div>
        <mat-form-field class="no-line">
          <input matInput disabled class="file-input">
          <input type="file" class="file-input" accept="image/*" (change)="onFileSelected($event)"
                 formControlName="contentImageSource" #fileUpload>
          <button class="change-icon" mat-flat-button color="accent" (click)="fileUpload.click()">{{'BUTTONS.CHANGE_IMAGE' | translate}}</button>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field color="accent">
          <mat-select formControlName="contentSeas" placeholder="{{'PAGES.CONTENT.BY_SEA' | translate}}" [multiple]="true" required>
            <mat-option [value]="contentSeas.MEDITERRANEAN">{{'PAGES.CONTENT.SEAS.MEDITERRANEAN' | translate}}</mat-option>
            <!--<mat-option [value]="contentSeas.RED">{{'PAGES.CONTENT.SEAS.RED' | translate}}</mat-option>-->
          </mat-select>
        </mat-form-field>

        <mat-checkbox formControlName="isPrioritized">{{'PAGES.CONTENT.IS_PRIORITIZED' | translate}}</mat-checkbox>

        <mat-form-field class="group-id">
          <mat-select formControlName="groupId" placeholder="{{'PAGES.CONTENT.GROUP' | translate}}" required>
            <mat-option *ngFor="let group of contentGroups" [value]=group.id>{{group.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="checkError('groupId', 'required')">{{'PAGES.CONTENT.ERRORS.REQUIRED_GROUP' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{'PAGES.CONTENT.ORDER' | translate}}</mat-label>
          <input matInput formControlName="contentOrder" required type="number" [min]="0" [max]="appConstants.maximumOrderValue">
          <mat-error *ngIf="checkError('contentOrder', 'required')">{{'PAGES.CONTENT.ERRORS.REQUIRED_CONTENT_ORDER' | translate}}</mat-error>
          <mat-error *ngIf="checkError('contentOrder', 'min')">{{'PAGES.CONTENT.ERRORS.TOO_SMALL_CONTENT_ORDER' | translate}}</mat-error>
          <mat-error *ngIf="checkError('contentOrder', 'max')">{{'PAGES.CONTENT.ERRORS.TOO_BIG_CONTENT_ORDER' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
