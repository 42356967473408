<div class="crud-page">
  <div class="page-header">
    <a routerLink="/backoffice-users" class="link back-button">
      <mat-icon>arrow_left</mat-icon>
      {{ "PAGES.BACKOFFICE_USERS.BACK" | translate }}</a
    >
    <h1>{{ "PAGES.BACKOFFICE_USER_ADD.TITLE" | translate }}</h1>
    <button class="toolbar" mat-flat-button color="primary" (click)="addBackofficeUser()" [disabled]="!form.valid">
      {{ "BUTTONS.ADD_USER" | translate }}
    </button>
  </div>
  <div class="page-content">
    <form [formGroup]="form" class="vertical-form columns">
      <div>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'PAGES.BACKOFFICE_USERS.FULL_NAME' | translate }}"
            type="text"
            formControlName="fullName"
            required
          />
          <mat-error *ngIf="checkError('fullName', 'required')">{{
            "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_FULL_NAME" | translate
          }}</mat-error>
          <mat-error *ngIf="checkError('fullName', 'pattern')">{{ "ERROR_MESSAGES.PATTERNS.NAME" | translate }}</mat-error>
        </mat-form-field>
        <div class="field-and-checkboxes">
          <mat-form-field>
            <input matInput placeholder="{{ 'PAGES.BACKOFFICE_USERS.EMAIL' | translate }}" type="text" formControlName="email" required />
            <mat-error *ngIf="checkError('email', 'required')">{{ "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_EMAIL" | translate }}</mat-error>
            <mat-error *ngIf="checkError('email', 'email')">{{ "PAGES.BACKOFFICE_USERS.ERRORS.INVALID_EMAIL" | translate }}</mat-error>
          </mat-form-field>
          <mat-checkbox
            [(ngModel)]="receiveEmail"
            *ngIf="form.get('role')?.value === userRoles.INSPECTOR"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div class="field-and-checkboxes">
          <mat-form-field style="flex: 2">
            <input
              matInput
              placeholder="{{ 'PAGES.BACKOFFICE_USERS.MOBILE' | translate }}"
              type="text"
              formControlName="mobile"
              [required]="form.get('role')?.value === userRoles.INSPECTOR"
            />
            <mat-error *ngIf="checkError('mobile', 'pattern')">{{ "ERROR_MESSAGES.PATTERNS.MOBILE" | translate }}</mat-error>
            <mat-error *ngIf="checkError('mobile', 'conditionalMobile')">{{
              "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_MOBILE" | translate
            }}</mat-error>
          </mat-form-field>
          <div class="checkboxes" *ngIf="form.get('role')?.value === userRoles.INSPECTOR && !!form.get('mobile')?.value">
            <mat-checkbox class="vertical-layout" [(ngModel)]="receiveSMS" [ngModelOptions]="{ standalone: true }">{{
              "PAGES.MESSAGES_MANAGEMENT.SMS" | translate
            }}</mat-checkbox>
          </div>
        </div>
        <mat-form-field>
          <mat-select placeholder="{{ 'PAGES.BACKOFFICE_USERS.ROLE' | translate }}" formControlName="role" required>
            <mat-option [value]="userRoles.ADMIN">{{ "ROLES.ADMIN" | translate }}</mat-option>
            <mat-option [value]="userRoles.INSPECTOR">{{ "ROLES.INSPECTOR" | translate }}</mat-option>
            <mat-option [value]="userRoles.REPORT_USER">{{ "ROLES.REPORT_USER" | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="checkError('role', 'required')">{{ "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_ROLE" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="form.get('role')?.value === userRoles.INSPECTOR">
        <mat-form-field class="half-width">
          <mat-label>{{ "PAGES.BACKOFFICE_USERS.CATEGORIES" | translate }}</mat-label>
          <mat-select
            formControlName="categories"
            multiple
            (opened)="setFocus('categories')"
            (closed)="resetSearchText('categories')"
            [required]="form.get('role')?.value === userRoles.INSPECTOR"
          >
            <input
              #searchCategoryInput
              [(ngModel)]="searchCategoryText"
              [ngModelOptions]="{ standalone: true }"
              autocomplete="off"
              matInput
              placeholder="{{ 'PAGES.BACKOFFICE_USERS.SEARCH' | translate }}"
              class="search-select"
            />
            <mat-option *ngFor="let category of categories | search : searchCategoryText : 'name'" [value]="category.id">
              {{ category.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError('categories', 'conditionalCategory')">{{
            "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_CATEGORY" | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="half-width">
          <mat-label>{{ "PAGES.BACKOFFICE_USERS.RESERVES" | translate }}</mat-label>
          <mat-select
            formControlName="reserves"
            multiple
            (opened)="setFocus('reserves')"
            (closed)="resetSearchText('reserves')"
            [required]="form.get('role')?.value === userRoles.INSPECTOR"
          >
            <input
              #searchReserveInput
              [(ngModel)]="searchReserveText"
              [ngModelOptions]="{ standalone: true }"
              autocomplete="off"
              matInput
              placeholder="{{ 'PAGES.BACKOFFICE_USERS.SEARCH' | translate }}"
              class="search-select"
            />
            <mat-option *ngFor="let reserve of reserves | search : searchReserveText : 'name'" [value]="reserve.id">
              {{ reserve.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError('reserves', 'conditionalReserve')">{{
            "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_RESERVE" | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
